import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

export const DEFAULT_STYLEGUIDE_PLATFORM = 'html';
export const STYLEGUIDE_PLATFORMS = ['html', 'react', 'android', 'ios'];
// local storage keys
export const STORAGE_KEY_HONEYCOMB_STYLEGUIDE_PLATFORM = 'honeycomb-styleguide-platform';

export const PlatformContext = React.createContext({
  platform: DEFAULT_STYLEGUIDE_PLATFORM,
  setPlatform: () => {},
});

function PlatformContextProvider({ children }) {
  const [platform, setPlatform] = useState(DEFAULT_STYLEGUIDE_PLATFORM);
  // load stored platform from the local storage on first load
  useEffect(() => {
    const storedTheme = localStorage.getItem(STORAGE_KEY_HONEYCOMB_STYLEGUIDE_PLATFORM);
    setPlatform(storedTheme || DEFAULT_STYLEGUIDE_PLATFORM);
  }, []);
  // updating storage
  useEffect(() => {
    const validatedPlatform = STYLEGUIDE_PLATFORMS.indexOf(platform) >= 0
      ? platform
      : DEFAULT_STYLEGUIDE_PLATFORM;
    localStorage.setItem(STORAGE_KEY_HONEYCOMB_STYLEGUIDE_PLATFORM, validatedPlatform);
  }, [platform]);
  return (
    <PlatformContext.Provider
      value={
        useMemo(() => (
          {
            platform,
            setPlatform,
          }
        ), [platform, setPlatform])
      }
    >
      {children}
    </PlatformContext.Provider>
  );
}

PlatformContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.shape({
    platform: PropTypes.oneOf(['react', 'html', 'android', 'ios']),
    setPlatform: PropTypes.func,
  }),
};

export default PlatformContextProvider;
